import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { Api } from "../api/axiosInstance";
import { sortRecords } from "../helpers/utils";

export type ImageResizingDataHash = {
  id: string | number;
  unique_key: string | number;
  filename: string;
  status: string;
  created_at: string;
  created_by: string;
  total_count: number | null;
  success_count: number | null;
  error_count: number | null;
  processed_response_present: boolean;
};

type ImageResizingData = {
  data: ImageResizingDataHash[];
  total_count: number;
};

type ImageResizingApiState = {
  ImageResizingData?: ImageResizingData | null;
  imageResizingStatus: "idle" | "loading" | "failed";
  imageResizingError: string | null;
  sortDirection: "asc" | "desc";
  sortedColumn: keyof ImageResizingDataHash | null;
};

const initialState: ImageResizingApiState = {
  ImageResizingData: null,
  imageResizingStatus: "loading",
  imageResizingError: null,
  sortDirection: "asc",
  sortedColumn: null,
};

type ErrorResponse = {
  errors: string;
};

const formatRequest = (requestData: any, download: boolean = false): string => {
  let url = `/solomon/image_resizing?`;
  url += `page=${requestData.page + 1}&`;
  url += `per_page=${requestData.rowsPerPage}`;
  return url;
};

export const imageResizingApi = createAsyncThunk("imageResizingApi", async ({ headers, ...requestData }: any, { rejectWithValue }) => {
  const formattedUrl = formatRequest(requestData);
  return await Api({ url: formattedUrl, method: "GET", rejectWithValue });
});

export const uploadImageResizing = createAsyncThunk("uploadImageResizing", async ({ headers, payload }: any, { rejectWithValue }) => {
  const formattedRequestUrl = "/solomon/image_resizing";
  return await Api({ url: formattedRequestUrl, method: "POST", headers, data: payload, rejectWithValue });
});

export const downloadResponseApi = createAsyncThunk("downloadResponseApi", async ({ id, headers }: any, { rejectWithValue }) => {
  const formattedRequestUrl = `/solomon/image_resizing/${id}/download_processed_response`;
  return await Api({ url: formattedRequestUrl, method: "GET", rejectWithValue });
});

export const downloadAttachedFileApi = createAsyncThunk("downloadAttachedFileApi", async ({ id, headers }: any, { rejectWithValue }) => {
  const formattedRequestUrl = `/solomon/image_resizing/${id}/attachment_urls`;
  return await Api({ url: formattedRequestUrl, method: "GET", rejectWithValue });
});

export const downloadResizedAttachmentApi = createAsyncThunk("downloadResizedAttachmentApi", async ({ id, headers }: any, { rejectWithValue }) => {
  const formattedRequestUrl = `/solomon/image_resizing/${id}/download_resized_images`;
  return await Api({ url: formattedRequestUrl, method: "GET", rejectWithValue });
});

const imageResizingSlice = createSlice({
  name: "imageResizingTrackingSlice",
  initialState,
  reducers: {
    setSort: (state, action: PayloadAction<{ column: keyof ImageResizingDataHash | null; direction: "asc" | "desc" }>) => {
      const { column, direction } = action.payload;
      // Sorting logic
      if (state.ImageResizingData?.data && column) {
        const sorted = sortRecords(state.ImageResizingData.data, column, direction);
        state.ImageResizingData.data = sorted as ImageResizingDataHash[];
        state.sortDirection = direction;
        state.sortedColumn = column;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(imageResizingApi.pending, (state) => {
        state.imageResizingStatus = "loading";
        state.imageResizingError = null;
      })
      .addCase(imageResizingApi.fulfilled, (state, action: PayloadAction<ImageResizingData>) => {
        state.imageResizingStatus = "idle";
        state.ImageResizingData = action.payload;
      })
      .addCase(imageResizingApi.rejected, (state, action) => {
        state.imageResizingStatus = "failed";
        if (action.payload) {
          state.imageResizingError = (action.payload as ErrorResponse).errors || "Error occured";
        } else {
          state.imageResizingError = action.error.message || "Error occured";
        }
      });
  },
});

export const { setSort } = imageResizingSlice.actions;
export default imageResizingSlice.reducer;
