import { Box, IconButton } from '@mui/material';
import React, { useState } from 'react';
import styles from './VariantDetails.module.scss';
import DeleteIcon from '@mui/icons-material/Delete';
import SelectX from 'components/atoms/SelectX/SelectX';
import InputX from 'components/atoms/InputX/InputX';
import ButtonX from 'components/atoms/ButtonX/ButtonX';
import { ProductFormData, VariantData, VariantRowData } from 'slices/productFormSlice';
import { useParams } from 'react-router-dom';
import EditProductVariant from '../EditProductVariant/EditProductVariant';

export type VarintErrors = {
  variantName: string;
  skuId: string;
  costPrice: string;
  mrp: string;
  listingPrice: string;
  stock: string;
}

interface VariantDetailsPropType {
  variantErrors: VarintErrors[];
  variantDetails: VariantData;
  onChange: (field: keyof VariantData, value: any) => void;
  validationErrors: Record<keyof VariantData, string | undefined>;
  setVariantErrors: (errors: VarintErrors[]) => void;
  isEditMode: boolean;
}

const VariantDetails: React.FC<VariantDetailsPropType> = ({
  variantDetails,
  onChange,
  validationErrors,
  variantErrors,
  setVariantErrors,
  isEditMode,
}) => {
  const { id } = useParams<{ id?: string }>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedVariant, setSelectedVariant] = useState<string>(variantDetails.variantType || '');
  const [variantRows, setVariantRows] = useState<VariantRowData[]>(variantDetails.variantRowData || []);
  const [editingVariant, setEditingVariant] = useState<string | number>('');

  const variantTypes = [
    { label: 'Size', value: 'size' },
    { label: 'Color', value: 'color' },
    { label: 'Set Of', value: 'set_of' },
    { label: 'Title', value: 'title'}
  ];

  const handleSelectChange = (value: string) => {
    setSelectedVariant(value);
    onChange('variantType', value);
  };

  const createNewRow = (): VariantRowData => ({
    variantName: '',
    skuId: '',
    costPrice: 0,
    mrp: 0,
    listingPrice: 0,
    stock: 0,
    id: null,
    marketplace_unique_id: null,
  });

  const addNewRow = () => {
    const newRow = createNewRow();
    const updatedRows = [...variantRows, newRow];
    if(!!variantErrors.length) setVariantErrors([...variantErrors, {variantName: '' , skuId: '' , listingPrice: '' , costPrice: '' , stock: '', mrp: ''}]);
    setVariantRows(updatedRows);
    onChange('variantRowData', updatedRows);
  };

  const updateErrors = (index: number, field: keyof VariantRowData) => {
    if(!!variantErrors.length){
      if(['mrp' , 'listingPrice', 'costPrice'].includes(field)){
        variantErrors[index] = {
          ...variantErrors[index],
          mrp: '', listingPrice: '', costPrice: '',
        }
      }else{
        variantErrors[index] = {
          ...variantErrors[index],
          [field]: '',
        }
      }
      setVariantErrors(variantErrors);
    }
  }

  const handleInputChange = (index: number, field: keyof VariantRowData, value: any) => {
    const updatedRows = [...variantRows];
    updatedRows[index] = { ...updatedRows[index], [field]: value };
    setVariantRows(updatedRows);
    updateErrors(index, field);
    onChange('variantRowData', updatedRows);
  };

  const handleNumberInputChange = (index: number, field: keyof VariantRowData, value: string) => {
    const numericValue = parseFloat(value) || 0;
    handleInputChange(index, field, numericValue);
    updateErrors(index, field);
  };

  const removeRow = (index: number) => {
    const updatedRows = variantRows.filter((_, i) => i !== index);
    setVariantRows(updatedRows);
    onChange('variantRowData', updatedRows);
    if(!!variantErrors){
      const updatedErrors = variantErrors.filter((_, i) => i !== index);
      setVariantErrors(updatedErrors);
    }
  };

  const handleEditClick = (variantMarketplaceId: string | number) => {
    setEditingVariant(variantMarketplaceId);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditingVariant('');
    window.location.reload();
  };

  return (
    <Box className={styles.container}>
      {/* Row 1: Variant Type Selector */}
      <Box className={styles.variantType}>
        <SelectX
          label="Variant type"
          wrapperClass={styles.textField}
          options={variantTypes}
          value={selectedVariant}
          error={validationErrors.variantType}
          onChange={(value) => {
            validationErrors.variantType = '';
            handleSelectChange(value);
          }}
          allowSearch
          disabled={!!selectedVariant && isEditMode}
        />
      </Box>

      {/* Horizontal Line (SVG) below the dropdown */}
      {selectedVariant && (
        <>
          <Box className={styles.horizontalLine}>
            <svg xmlns="http://www.w3.org/2000/svg" width="1336" height="2" viewBox="0 0 1336 2" fill="none">
              <path d="M0 1H1336" stroke="#E5E5E5" />
            </svg>
          </Box>

          {/* Label for Variant List */}
          <Box className={styles.labelLeft}>Variant List</Box>

          {/* Row 2: Variant List */}
          <Box className={styles.variantList}>
            {variantRows.map((row, index) => (
              <Box key={index} className={styles.variantRow}>
                <InputX
                  wrapperClass={styles.textField}
                  label="Variant Name"
                  placeholder="name"
                  error={!!variantErrors.length ? variantErrors[index].variantName: undefined}
                  value={row.variantName}
                  onChange={(e) => handleInputChange(index, 'variantName', e.target.value)}
                />

                <InputX
                  wrapperClass={styles.textField}
                  label="SKU ID"
                  placeholder="SKU ID"
                  error={!!variantErrors.length ? variantErrors[index].skuId: undefined}
                  value={row.skuId}
                  disabled={!!row.id}
                  onChange={(e) => handleInputChange(index, 'skuId', e.target.value)}
                />

                <InputX
                  wrapperClass={styles.textField}
                  label="Cost Price"
                  placeholder="Cost Price"
                  error={!!variantErrors.length ? variantErrors[index].costPrice: undefined}
                  value={row.costPrice}
                  onChange={(e) => handleNumberInputChange(index, 'costPrice', e.target.value)}
                  InputProps={{ startAdornment: '₹' }}
                />

                <InputX
                  wrapperClass={styles.textField}
                  label="MRP"
                  placeholder="MRP"
                  error={!!variantErrors.length ? variantErrors[index].mrp: undefined}
                  value={row.mrp}
                  onChange={(e) => handleNumberInputChange(index, 'mrp', e.target.value)}
                  InputProps={{ startAdornment: '₹' }}
                />

                <InputX
                  wrapperClass={styles.textField}
                  label="Listing Price"
                  placeholder="Listing Price"
                  error={!!variantErrors.length ? variantErrors[index].listingPrice: undefined}
                  value={row.listingPrice}
                  onChange={(e) => handleNumberInputChange(index, 'listingPrice', e.target.value)}
                  InputProps={{ startAdornment: '₹' }}
                />

                <InputX
                  wrapperClass={styles.textField}
                  label="Stock"
                  placeholder="Stock"
                  error={!!variantErrors.length ? variantErrors[index].stock: undefined}
                  value={row.stock}
                  disabled
                />

                <Box className={styles.actionButton}>
                  {row.id && (
                    <ButtonX 
                      variant="outlined" color="#000" size="medium" 
                      disabled={!row.marketplace_unique_id}
                      onClick={() => handleEditClick(row.marketplace_unique_id!)}
                    >
                      Edit
                    </ButtonX>
                  )}
                  {!row.id && (
                    <DeleteIcon color="error" onClick={() => removeRow(index)} />
                  )}
                </Box>
              </Box>
            ))}
          </Box>

          <ButtonX variant="outlined" onClick={addNewRow} className={styles.addMoreBtn} color="#000" size="small">
            Add more
          </ButtonX>
        </>
      )}

      {isModalOpen &&
        <EditProductVariant
          open={isModalOpen}
          onClose={handleCloseModal}
          variantMarketplaceId={editingVariant || ''}
        />
      }
    </Box>
  );
};

export default VariantDetails;
