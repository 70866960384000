import {ColumnPropType} from "../../components/organisms/TableManager/TableManager"
import styles from "./ImageResizing.module.scss";
import { ImageResizingDataHash } from "slices/imageResizingSlice";
import { DEFAULT_BLACK_COLOR } from "../../constants";
import ButtonX from "components/atoms/ButtonX/ButtonX";
import { Box } from "@mui/material";

type ImageResizingColumnsPropType = {
  downloadAttachments: (id: string | number) => void;
  downloadResponse: (id: string | number) => void;
  downloadResizedImages: (id: string | number) => void;
}

export const imageResizingColumns = (props: ImageResizingColumnsPropType): ColumnPropType<ImageResizingDataHash>[]  =>  [
  {
    label: 'File Name',
    accessor: 'filename',
    minWidth: 300,
  },
  {
    label: 'Created At',
    accessor: 'created_at',
    minWidth: 160,
  },
  {
    label: 'Created By',
    accessor: 'created_by',
    minWidth: 220,
  },
  {
    label: 'Processed Status',
    accessor: 'unique_key',
    minWidth: 200,
    cell: ({row}) => {
      const {success_count, total_count} = row;
      if(total_count === null){
        return 'NA'
      }
      return  `${success_count}/${total_count}`;
    }
  },
  {
    label: 'Status',
    accessor: 'status',
    minWidth: 150,
    cell: ({value}) => {
      const pillStyleClass = pillClass(value);
      return <p className={pillStyleClass}>{value}</p>
    }
  },
  {
    label: 'Actions',
    accessor: 'id',
    minWidth: 220,
    cell: ({row}) => {
      const {id, processed_response_present, status} = row;
      return (
        <Box className={styles.actionBtns}>
          <ButtonX
            variant="outlined"
            size="small"
            color={DEFAULT_BLACK_COLOR} 
            onClick={() => props.downloadAttachments(id)}
          >
            Attachment
          </ButtonX>
          <ButtonX
            variant="outlined"
            size="small"
            color={DEFAULT_BLACK_COLOR} 
            onClick={() => props.downloadResizedImages(id)}
            disabled={status === 'Failed' || status === 'Processing'}
          >
            Resized Images
          </ButtonX>
          <ButtonX
            variant="outlined"
            size="small"
            color={DEFAULT_BLACK_COLOR} 
            onClick={() => props.downloadResponse(id)}
            disabled={!processed_response_present}
          >
            Download
          </ButtonX>
        </Box>
      );
    },
  },
]

export const pillClass = (status: string) => {
  switch(status.toLowerCase()){
    case 'failed':
      return styles['red-pill'];
    case 'success':
      return styles['green-pill'];
    default:
      return styles['orange-pill'];
  }
}