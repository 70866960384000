import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { Box } from "@mui/material";
import { RootState } from "../../store";
import styles from "./ImageResizing.module.scss";
import { downloadAttachedFileApi, downloadResponseApi, downloadResizedAttachmentApi, uploadImageResizing } from "slices/imageResizingSlice";
import Loader from "components/atoms/Loader/Loader";
import { resetAuth } from "../../slices/authSlice";
import TableManager from "../../components/organisms/TableManager/TableManager";
import MainHeader from "../../components/atoms/MainHeader/MainHeader";
import ButtonX from "components/atoms/ButtonX/ButtonX";
import { imageResizingApi, ImageResizingDataHash } from "slices/imageResizingSlice";
import { imageResizingColumns } from "./ImageResizing.constant";
import UploadModal from "./UploadModal";
import { downloadFile } from "helpers/utils";

const PAGE_TITLE = "Image Resizing";

const ImageResizing = () => {
  const dispatch = useAppDispatch();

  const [loaderActive, setLoaderActive] = React.useState<Boolean>(true);
  const [filterApplied, setFilterApplied] = React.useState<Boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [fileUploading, setFileUploading] = useState<boolean>(false);

  const imageResizingData = useAppSelector(
    (state: RootState) => state.imageResizing
  );

  const tableData = imageResizingData.ImageResizingData?.data || [];
  const totalCount: number = imageResizingData?.ImageResizingData?.total_count || 0;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  useEffect(() => {
    if (filterApplied) {
      setLoaderActive(true);
      fetchData();
    }
  }, [dispatch, filterApplied]);

  const handleRequest = async (handler: (headers: { Authorization: string } | undefined) => Promise<any>) => {
    const userInfo = localStorage.getItem('userInfo');
    if (userInfo) {
      let data: any = undefined;
      try {
        const parsedUserInfo = JSON.parse(userInfo);
        const token = parsedUserInfo.token;
        const headers = token ? { Authorization: `Bearer ${token}` } : undefined;
        data = await handler(headers);
      } catch (e) {
        console.error(e);
      } finally {
        setFilterApplied(false);
        setLoaderActive(false);
        return data;
      }
    } else {
      dispatch(resetAuth());
    }
  }

  const fetchData = () => {
    const payload = { page, rowsPerPage };
    let handler = (headers: { Authorization: string } | undefined) => dispatch(imageResizingApi({ headers, ...payload })).unwrap();
    handleRequest(handler);
  }

  const downloadAttachments = async (id: string | number) => {
    const payload = { id };
    let handler = (headers: { Authorization: string } | undefined) => dispatch(downloadAttachedFileApi({ headers, ...payload })).unwrap();
    const data = await handleRequest(handler);
    if (data) {
      const url = data?.url;
      const decodedUrl = decodeURIComponent(url);
      const match = decodedUrl.match(/(?<=filename=").*?(?=")/);
      const link = document.createElement('a');
      const fileName = match ? match[0] : 'Attachment.zip';
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  const downloadResponse = async (id: string | number) => {
    const payload = { id };
    let handler = (headers: { Authorization: string } | undefined) => dispatch(downloadResponseApi({ headers, ...payload })).unwrap();
    const downloadApi = async () => {
      const data = await handleRequest(handler);
      return { payload: data };
    };
    await downloadFile({ downloadApi, fileName: 'processed_response.csv', fileType: 'text/csv' });
  }

  const downloadResizedImages = async (id: string | number) => {
    const payload = { id };
    let handler = (headers: { Authorization: string } | undefined) => dispatch(downloadResizedAttachmentApi({ headers, ...payload })).unwrap();
    const data = await handleRequest(handler);
    if (data) {
      const url = data?.url;
      const decodedUrl = decodeURIComponent(url);
      const match = decodedUrl.match(/(?<=filename=").*?(?=")/);
      const link = document.createElement('a');
      const fileName = match ? match[0] : 'Attachment.zip';
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
    setTimeout(() => { setFilterApplied(true) }, 0);
  };

  const handleChangeRowsPerPage = (_rowsPerPage: number) => {
    setRowsPerPage(_rowsPerPage);
    setPage(0);
    setTimeout(() => { setFilterApplied(true) }, 0);
  };

  const uploadFileHandler = async (payload: FormData) => {
    setFileUploading(true);
    let handler = (headers: { Authorization: string } | undefined) => dispatch(uploadImageResizing({ headers, payload })).unwrap();
    await handleRequest(handler);
    setFileUploading(false);
    setShowModal(false)
    setFilterApplied(true);
  }

  return (
    <Box className={styles.reportWrapper}>
      <MainHeader label={PAGE_TITLE}>
        <ButtonX
          className={styles.primaryBtn}
          onClick={() => setShowModal(true)}
        >
          Resize Image
        </ButtonX>
      </MainHeader>

      <Loader show={loaderActive} />

      {!loaderActive && (
        <TableManager<ImageResizingDataHash>
          data={tableData}
          totalCount={totalCount}
          columns={imageResizingColumns({ downloadAttachments, downloadResizedImages, downloadResponse })}
          sortedColumn={imageResizingData.sortedColumn}
          handleSort={() => { }}
          sortDirection={imageResizingData.sortDirection}
          showPagination
          currentPage={page}
          rowPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 100]}
        />
      )}
      <UploadModal
        handleUpload={uploadFileHandler}
        open={showModal}
        onClose={() => !fileUploading && setShowModal(false)}
        uploading={fileUploading}
      />
    </Box>
  );
};

export default ImageResizing;
