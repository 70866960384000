import React, { useEffect, useState } from "react";
import { CollectionBannerFormData, CollectionsData } from "slices/collectionBannerFormSlice";
import styles from "./EditCollectionBanner.module.scss";
import { Typography, Box, Table, TableHead, TableRow, TableCell, TableBody, Checkbox } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import InputX from "components/atoms/InputX/InputX";
import ButtonX from "components/atoms/ButtonX/ButtonX";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AddCollectionModal from "./AddCollectionModal";

type EditCollectionBannerProps = {
  editFormData: CollectionBannerFormData;
  onChange: (field: keyof CollectionBannerFormData, value: any) => void;
  onRemoveCollections: (deletedIds: number[]) => void;
  validationErrors: Partial<Record<keyof CollectionBannerFormData, string | undefined>>;
};

const EditCollectionBanner = ({ editFormData, onChange, validationErrors, onRemoveCollections }: EditCollectionBannerProps) => {
  const [previewURL, setPreviewURL] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [deletedCollectionIds, setDeletedCollectionIds] = useState<number[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [collectionSearchTxt, setCollectionSearchTxt] = useState<string>('');

  // Toggle All Rows
  const handleSelectAll = () => {
    const collectionIds = editFormData.collections
      .map((c) => typeof c.id === "number" ? c.id : NaN)
      .filter((id): id is number => !isNaN(id));

    if (selectAll) {
      setSelectedItems([]);
    } else {
      setSelectedItems(collectionIds);
    }

    setSelectAll(!selectAll);
  };

  // Toggle Individual Row
  const handleCheckboxChange = (id: number) => {
    setSelectedItems((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  // Handle Image Upload
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const previewURL = URL.createObjectURL(file);
      setPreviewURL(previewURL);
      onChange("banner", file);
    }
  };

  useEffect(() => {
    if (editFormData.banner instanceof File) {
      const previewUrl = URL.createObjectURL(editFormData.banner);
      setPreviewURL(previewUrl);
      return () => {
        URL.revokeObjectURL(previewUrl);
      };
    } else {
      setPreviewURL(editFormData.banner);
    }
  }, [editFormData.banner]);

  // Remove Image
  const handleRemoveImage = () => {
    setPreviewURL(null);
    onChange("banner", "");
  };

  const handleRemove = (ids: number | number[]) => {
    const idsToRemove = Array.isArray(ids) ? ids : [ids];
    onRemoveCollections(idsToRemove);
    setDeletedCollectionIds((prev) => [...new Set([...prev, ...idsToRemove])]);
    setSelectedItems((prev) => prev.filter((id) => !idsToRemove.includes(id)));
  };

  const isRowDeleted = (id: number | string) => {
    if (typeof id !== "number") return false;
    return deletedCollectionIds.includes(id);
  };

  const handleAddCollections = (newCollections: CollectionsData[]) => {
    setShowModal(false)
    const updatedCollections = [
      ...editFormData.collections,
      ...newCollections.filter(
        (newItem) =>
          !editFormData.collections.some((existingItem) => existingItem.id === newItem.id)
      ),
    ];

    onChange("collections", updatedCollections);
  };

  return (
    <Box className={styles.wrapper}>
      {/* Left Section */}
      <Box className={styles.leftSection}>
        {/* File Upload */}
        <Box className={styles.modalContent}>
          <label className={styles.label}>Banner Image</label>
          <div
            className={`${styles.uploadBox} ${error ? styles.errorBorder : ""}`}
            onClick={() => document.getElementById("banner-file-input")?.click()}
          >
            {!previewURL ? (
              <ButtonX
                size="large"
                className={styles.primaryBtn}
              >
                Click to add
              </ButtonX>
            ) : (
              <>
                <img src={previewURL} alt="Uploaded Preview" className={styles.previewImage} />
                {(
                  <Box className={styles.removeBtn} onClick={(e) => {
                    e.stopPropagation()
                    handleRemoveImage()
                  }}>Remove</Box>
                )}
              </>
            )}
          </div>
          {/* Hidden File Input */}
          <input
            id="banner-file-input"
            type="file"
            accept="image/png, image/jpeg, image/avif"
            onChange={handleFileChange}
          />

          {/*  Error Message */}
          {error && (
            <div className={styles.errorMessage}>
              <InfoOutlinedIcon className={styles.infoIcon} /> {error}
            </div>
          )}
        </Box>

        {/* Input Fields */}
        <Box className={styles.modalContent}>
          <InputX
            label="Banner Name"
            value={editFormData.banner_name}
            onChange={(e) => onChange("banner_name", e.target.value)}
            fullWidth
            error={validationErrors.banner_name}
          />
        </Box>

        <Box className={styles.modalContent}>
          <InputX
            label="Banner URL"
            value={editFormData.banner_link}
            onChange={(e) => onChange("banner_link", e.target.value)}
            fullWidth
            error={validationErrors.banner_link}
          />
        </Box>

        <Box className={styles.inputField}>
          <InputX
            label="Image Alt Text"
            value={editFormData.banner_alt_text}
            onChange={(e) => onChange("banner_alt_text", e.target.value)}
            fullWidth
            error={validationErrors.banner_alt_text}
          />
        </Box>
      </Box>

      {/* Right Section - Collection Table */}
      <Box className={styles.rightSection}>
        {/* Header with Add Button */}
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="subtitle1" className={styles.collectionCount}>
            Collections <span>• {editFormData.collections.length} Collections</span>
          </Typography>
          <InputX
            value={collectionSearchTxt}
            onChange={(e) => {
              setCollectionSearchTxt(e.target.value || '');
            }}
            placeholder="Search Collections"
            wrapperClass={styles.collectionSearch}
          />
          <Box display="flex" flexDirection="column" alignItems="flex-end" gap={1}>
            {/* Add Collection Button */}
            <ButtonX
              variant="contained"
              onClick={() => setShowModal(true)}
              disabled={selectedItems.length > 0}
            >
              Add Collection
            </ButtonX>
          </Box>
        </Box>
        <Box display="flex" justifyContent="flex-end" mb={2}>
          {selectedItems.length > 0 && (
            <ButtonX
              color='#000'
              variant="outlined"
              onClick={() => handleRemove(selectedItems)}
            >
              Remove
            </ButtonX>
          )}
        </Box>
        {editFormData.collections.length > 0 && <Table>
          {/* Table Head */}
          <TableHead>
            <TableRow>
              <TableCell>
                <Box display="flex" alignItems="center">
                  <Checkbox
                    checked={selectAll}
                    onChange={handleSelectAll}
                    color="primary"
                  />
                  <Typography variant="body1">Collection Name</Typography>
                </Box>
              </TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>

          {/* Table Body */}
          <TableBody>
            {editFormData.collections.map((collection) => {
              if (!(collection.name || '').toLocaleLowerCase().includes(collectionSearchTxt.toLocaleLowerCase())) return null;
              return <TableRow key={collection.id}
                className={isRowDeleted(collection.id) ? styles.deletedRow : ""}
              >
                {/* Checkbox + Collection Details */}
                <TableCell>
                  <Box display="flex" alignItems="center">
                    <Checkbox
                      checked={selectedItems.includes(Number(collection.id))}
                      onChange={() => handleCheckboxChange(Number(collection.id))}
                    />
                    <Box className={styles.collectionWrapper} ml={1}>
                      <img
                        src={collection.image_url || ''}
                        alt={collection.name || ""}
                        className={styles.collectionImage}
                      />
                      <Box className={styles.collectionDetails}>
                        <Typography variant="body1" className={styles.collectionName}>
                          {collection.name}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          {collection.marketplace_unique_id}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </TableCell>

                {/* Action */}
                <TableCell align="center">
                  <DeleteIcon
                    className={isRowDeleted(collection.id) ? styles.disabledIcon : styles.activeIcon}
                    onClick={() => {
                      if (!isRowDeleted(collection.id)) {
                        handleRemove(Number(collection.id))
                      }
                    }}
                  />
                </TableCell>

              </TableRow>
            })}
          </TableBody>
        </Table>
        }
      </Box>
      <AddCollectionModal
        alreadySelectedIds={editFormData.collections
          .map((item) => item.id)
          .filter((id): id is number => typeof id === "number")} // Include only numeric IDs
        open={showModal}
        onClose={() => setShowModal(false)}
        onSubmit={(selectedCollections) => handleAddCollections(selectedCollections)}
      />
    </Box>
  );
};

export default EditCollectionBanner;
