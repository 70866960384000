import { useCallback, useEffect, useRef, useState } from "react";
import styles from "./ImageResizing.module.scss";
import ModalBox from "components/atoms/ModalBox/ModalBox";
import { Box } from "@mui/material";
import { DragArea, DragAreaPropType, FileItem } from "components/molecules/FileUpload/FileUpload";
import { DEFAULT_BLACK_COLOR } from "../../constants";
import ButtonX from "components/atoms/ButtonX/ButtonX";

type PropType = {
  handleUpload: (formData: FormData) => void;
  open: boolean;
  onClose: () => void;
  uploading?: boolean;
}

const UploadModal: React.FC<PropType> = (props) => {
  const { handleUpload, open, onClose, uploading } = props;
  const [zipFile, setZipFile] = useState<File | undefined>(undefined);

  const zipUploadRef = useRef<HTMLInputElement | null>(null);
  const heading = 'Resize Image';

  useEffect(() => {
    if (open) {
      setZipFile(undefined)
    }
  }, [open]);

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>, type: 'zip') => {
    if (e.target.files?.length) {
      setZipFile(e.target.files[0]);
    }
    e.target.value = "";
  }

  const handleSumit = () => {
    const formData = new FormData();
    if (zipFile) formData.append('zip_file', zipFile);
    handleUpload(formData);
  }

  const onDragOver = useCallback((e: React.DragEvent<HTMLDivElement>, type: 'zip') => {
    e.preventDefault();
    e.stopPropagation();
    const { classList } = e.target as HTMLDivElement;
    classList.add(styles.withDrag);
  }, []);

  const onDragLeave = useCallback((e: React.DragEvent<HTMLDivElement>, type: 'zip') => {
    e.preventDefault();
    e.stopPropagation();
    const { classList } = e.target as HTMLDivElement;
    classList.remove(styles.withDrag);
  }, []);

  const onDrop = (e: React.DragEvent<HTMLDivElement>, type: 'zip') => {
    e.preventDefault();
    e.stopPropagation();
    const { classList } = e.target as HTMLDivElement;
    classList.remove(styles.withDrag);
    if (e.dataTransfer?.files?.length) {
      if (type === 'zip' && e.dataTransfer.files[0].type === 'application/zip') {
        setZipFile(e.dataTransfer.files[0]);
      }
    }
  };

  return (
    <ModalBox open={open} onClose={onClose} className={styles.fileUploadModal}>
      <p className={styles.label}>{heading}</p>
      {!zipFile && (
        <InputSection
          heading='Upload zip file'
          subHeading=''
          handleUpload={() => { zipUploadRef?.current?.click(); }}
          onDragLeave={(e) => { onDragLeave(e, 'zip') }}
          onDragOver={(e) => { onDragOver(e, 'zip') }}
          onDrop={(e) => { onDrop(e, 'zip') }}
        />
      )}
      {(zipFile) && (
        <Box className={styles.uploadedFiles}>
          {zipFile && <FileItem file={zipFile} onDelete={() => setZipFile(undefined)} />}
        </Box>
      )}
      <Box className={styles.actionBtns}>
        <ButtonX color={DEFAULT_BLACK_COLOR} variant="outlined" size='large' onClick={onClose}>Discard</ButtonX>
        <ButtonX size='large' onClick={handleSumit} disabled={!zipFile || uploading} loading={uploading}>
          {uploading ? 'Uploading...' : 'Upload'}
        </ButtonX>
      </Box>

      <input ref={zipUploadRef}
        type="file"
        onChange={(e) => { handleFileUpload(e, 'zip') }}
        style={{ display: 'none' }}
        accept={'.zip'}
      />
    </ModalBox>
  );
}

export default UploadModal;

type InputSectionPropType = {
  heading: string;
  subHeading: string;
} & DragAreaPropType

const InputSection: React.FC<InputSectionPropType> = (props) => {
  const { heading, subHeading, onDragLeave, onDragOver, onDrop, handleUpload } = props;
  return (
    <Box className={styles.inputSection}>
      <p className={styles.heading}>{heading}</p>
      <p className={styles.subHeading}>{subHeading}</p>
      <DragArea
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        onDrop={onDrop}
        handleUpload={handleUpload}
      />
    </Box>
  )
}