import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import resetPasswordSlice from "./slices/resetPasswordSlice";
import forgotPasswordSlice from "./slices/forgotPasswordSlice";
import notificationReducer from "./slices/notificationSlice";
import userReducer from "./slices/userSlice";
import filtersSliceReducer from "./slices/filtersSlice";
import productCatalogueReducer from "./slices/productCatalogueSlice";
import collectionCatalogueReducer from "./slices/collectionCatalogueSlice";
import categoryMasterCatalogueReducer from "./slices/categoryMasterCatalogueSlice";
import subCategoryMasterCatalogueReducer from "./slices/subCategoryMasterCatalogueSlice";
import productTypeMasterCatalogueReducer from "./slices/productTypeMasterCatalogueSlice";
import allMasterCatalogueReducer from "./slices/allMasterCatalogueSlice";
import masterFormReducer from "slices/masterFormSlice";
import categoryFormReducer from "slices/categoryFormSlice";
import subCategoryFormReducer from "slices/subCategoryFormSlice";
import productSearchSlice from "slices/productSearchSlice";
import collectionFormSlice from "slices/collectionFormSlice";
import { axiosMiddleware } from "./api/middleware";
import productFormReducer from "slices/productFormSlice";
import collectionProductsSlice from "slices/collectionProductsSlice";
import requestTrackingSlice from "slices/requestTrackingSlice";
import setPieceMasterCatalogue from "slices/setPieceMasterCatalogueSlice";
import bulkImageUpload from "slices/bulkImageUploadSlice";
import collectionBanner from "slices/collectionBannerSlice";
import collectionBannerReducer from "slices/collectionBannerFormSlice";
import vendorDataValidations from "slices/vendorDataValidationsSlice";
import imageResizing from "slices/imageResizingSlice";


const store = configureStore({
  reducer: {
    auth: authReducer,
    resetPassword: resetPasswordSlice,
    forgotPassword: forgotPasswordSlice,
    notification: notificationReducer,
    user: userReducer,
    productCatalogue: productCatalogueReducer,
    collectionCatalogue: collectionCatalogueReducer,
    categoryMasterCatalogue: categoryMasterCatalogueReducer,
    subCategoryMasterCatalogue: subCategoryMasterCatalogueReducer,
    productTypeMasterCatalogue: productTypeMasterCatalogueReducer,
    allMasterCatalogue: allMasterCatalogueReducer,
    masterForm: masterFormReducer,
    categoryForm: categoryFormReducer,
    subCategoryForm: subCategoryFormReducer,
    productForm: productFormReducer,
    filters: filtersSliceReducer,
    searchProduct: productSearchSlice,
    collectionForm: collectionFormSlice,
    collectionProducts: collectionProductsSlice,
    trackRequest: requestTrackingSlice,
    bulkImageUploadRequests: bulkImageUpload,
    collectionBanner: collectionBanner,
    collectionBannerForm: collectionBannerReducer,
    setPiecesMaster: setPieceMasterCatalogue,
    vendorDataValidationsRequests: vendorDataValidations,
    imageResizing: imageResizing
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(axiosMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
