import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { Api } from "../api/axiosInstance";
import { sortRecords } from "../helpers/utils";

export type CollectionBannerDataHash = {
  unique_key: number;
  id: number;
  banner_name: string | null;
  banner: string | null;
  created_at: string;
  created_by: string | null;
  collections_count: number;
  size: string;
  banner_alt_text: string | null;
};

type CollectionBannerData = {
  data: CollectionBannerDataHash[];
  total_count: number;
};

type CollectionBannerApiState = {
  collectionBannerData?: CollectionBannerData | null;
  collectionBannerStatus: "idle" | "loading" | "failed";
  collectionBannerError: string | null;
  sortDirection: "asc" | "desc";
  sortedColumn: keyof CollectionBannerDataHash | null;
};

const initialState: CollectionBannerApiState = {
  collectionBannerData: null,
  collectionBannerStatus: "loading",
  collectionBannerError: null,
  sortDirection: "asc",
  sortedColumn: null,
};

type ErrorResponse = {
  errors: string;
};

const format = (requestData: any, download: boolean = false): string => {
  let url = `/solomon/collection_banners?`;
  url += `q[display_name_i_cont]=${requestData.collectionBannerNameFilter}&`;
  url += `page=${requestData.page}&`;
  url += `per_page=${requestData.rowsPerPage}`;
  return url;
};

export const collectionBannersApi = createAsyncThunk("collectionBannersApi", async ({ headers, ...requestData }: any, { rejectWithValue }) => {
  const formattedUrl = format(requestData);
  return await Api({ url: formattedUrl, method: "GET", rejectWithValue });
});

const collectionBannerSlice = createSlice({
  name: "collectionBannerSlice",
  initialState,
  reducers: {
    setSort: (state, action: PayloadAction<{ column: keyof CollectionBannerDataHash | null; direction: "asc" | "desc" }>) => {
      const { column, direction } = action.payload;
      // Sorting logic
      if (state.collectionBannerData?.data && column) {
        const sorted = sortRecords(state.collectionBannerData.data, column, direction);
        state.collectionBannerData.data = sorted as CollectionBannerDataHash[];
        state.sortDirection = direction;
        state.sortedColumn = column;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(collectionBannersApi.pending, (state) => {
        state.collectionBannerStatus = "loading";
        state.collectionBannerError = null;
      })
      .addCase(collectionBannersApi.fulfilled, (state, action: PayloadAction<CollectionBannerData>) => {
        state.collectionBannerStatus = "idle";
        state.collectionBannerData = action.payload;
      })
      .addCase(collectionBannersApi.rejected, (state, action) => {
        state.collectionBannerStatus = "failed";
        if (action.payload) {
          state.collectionBannerError = (action.payload as ErrorResponse).errors || "Error occured";
        } else {
          state.collectionBannerError = action.error.message || "Error occured";
        }
      });
  },
});

export const { setSort } = collectionBannerSlice.actions;
export default collectionBannerSlice.reducer;
